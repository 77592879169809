import React, { useState, useEffect } from "react";
import {
  Sun,
  Moon,
  X,
  Menu,
  Calendar,
  GraduationCap,
  Clock,
  Github,
  ChevronLeft,
  ChevronRight,
  SquarePlus,
  Info,
} from "lucide-react";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaDownload, FaGithub } from "react-icons/fa";

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [appInfo, setAppInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setDarkMode(mediaQuery.matches);

    const handleChange = (e) => {
      setDarkMode(e.matches);
    };

    mediaQuery.addEventListener("change", handleChange);

    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  useEffect(() => {
    fetch("https://api.quanhd.net/tkb_app.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setAppInfo(data);
        setLoading(false);
      })
      .catch((err) => {
        setError("Không thể tải thông tin ứng dụng. Vui lòng thử lại sau.");
        setLoading(false);
        console.error("Fetch error:", err);
      });
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const screenshots = [
    {
      src: "/assets/images/2210132C 9_21_2024 11_57_19 PM.png",
      alt: "Màn hình đăng nhập",
      description: "Màn hình đăng nhập của ứng dụng",
    },
    {
      src: "/assets/images/2210132C 9_21_2024 11_53_36 PM.png",
      alt: "Màn hình xem thời khóa biểu",
      description: "Màn hình xem thời khóa biểu",
    },
    {
      src: "/assets/images/2210132C 9_21_2024 11_54_31 PM.png",
      alt: "Màn hình xem lịch thi",
      description: "Màn hình xem lịch thi",
    },
    {
      src: "/assets/images/2210132C 9_21_2024 11_55_26 PM.png",
      alt: "Màn hình xem chi tiết lịch",
      description:
        "Màn hình xem lịch hiển thị chi tiết các ngày có lịch học, lịch thi",
    },
    {
      src: "/assets/images/2210132C 9_21_2024 11_54_46 PM.png",
      alt: "Màn hình xem chi tiết lịch",
      description:
        "Màn hình xem lịch hiển thị chi tiết các ngày có lịch học, lịch thi",
    },
    {
      src: "/assets/images/2210132C 9_21_2024 11_54_57 PM.png",
      alt: "Màn hình xem chi tiết lịch",
      description:
        "Màn hình xem lịch hiển thị chi tiết các ngày có lịch học, lịch thi",
    },
    {
      src: "/assets/images/2210132C 9_21_2024 11_55_57 PM.png",
      alt: "Màn hình xem điểm theo học kỳ",
      description: "Màn hình xem điểm theo học kỳ",
    },
    {
      src: "/assets/images/2210132C 9_21_2024 11_56_11 PM.png",
      alt: "Màn hình xem điểm theo môn học",
      description: "Màn hình xem điểm theo môn học",
    },
    {
      src: "/assets/images/2210132C 9_21_2024 11_55_44 PM.png",
      alt: "Màn hình hiển thị Tab Cài Đặt",
      description: "Màn hình hiển thị Tab Cài Đặt",
    },
    {
      src: "/assets/images/2210132C 9_21_2024 11_55_35 PM.png",
      alt: "Màn hình hiển thị Tab Trang Cá Nhân",
      description: "Màn hình hiển thị Tab Trang Cá Nhân",
    },
    {
      src: "/assets/images/2210132C 9_21_2024 11_55_49 PM.png",
      alt: "Màn hình hiển thị Tab Thời Gian Biểu",
      description: "Màn hình hiển thị Tab Thời Gian Biểu",
    },
  ];
  const [currentDescription, setCurrentDescription] = useState(
    screenshots[0].description
  );
  return (
    <div className={`min-h-screen ${darkMode ? "dark" : ""}`}>
      <div className="min-h-screen bg-gradient-to-b from-indigo-100 to-pink-100 dark:from-gray-900 dark:to-purple-900 transition-colors duration-500">
        {/* Enhanced Header */}
        <motion.header
          className="bg-gradient-to-r from-indigo-500 to-pink-500 dark:from-indigo-700 dark:to-pink-700 text-white p-4 fixed w-full z-10 shadow-lg"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="container mx-auto flex justify-between items-center">
            <motion.h1
              className="text-3xl font-bold flex items-center"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <img
                src="/assets/images/icon.png"
                alt="QTimeTable"
                className="w-10 h-10 mr-2"
              />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-pink-300">
                QTimeTable
              </span>
            </motion.h1>
            <div className="flex items-center">
              <motion.button
                onClick={toggleDarkMode}
                className="mr-4 p-2 rounded-full hover:bg-white/20 transition-colors duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {darkMode ? (
                  <Sun className="w-6 h-6" />
                ) : (
                  <Moon className="w-6 h-6" />
                )}
              </motion.button>
              <nav className="hidden md:block">
                <ul className="flex space-x-6">
                  {["Tính năng", "Ảnh demo", "Giới thiệu", "Tải xuống"].map(
                    (item, index) => (
                      <motion.li
                        key={index}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <a
                          href={`#${item.toLowerCase().replace(" ", "-")}`}
                          className="hover:text-yellow-200 transition-colors duration-300"
                        >
                          {item}
                        </a>
                      </motion.li>
                    )
                  )}
                </ul>
              </nav>
              <motion.button
                onClick={toggleMenu}
                className="md:hidden p-2 rounded-full hover:bg-white/20 transition-colors duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {menuOpen ? (
                  <X className="w-6 h-6" />
                ) : (
                  <Menu className="w-6 h-6" />
                )}
              </motion.button>
            </div>
          </div>
          {menuOpen && (
            <motion.nav
              className="md:hidden mt-4"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <ul className="flex flex-col space-y-2">
                {["Tính năng", "Ảnh demo", "Giới thiệu", "Tải xuống"].map(
                  (item, index) => (
                    <motion.li
                      key={index}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <a
                        href={`#${item.toLowerCase().replace(" ", "-")}`}
                        className="block py-2 hover:bg-white/20 transition-colors duration-300"
                        onClick={toggleMenu}
                      >
                        {item}
                      </a>
                    </motion.li>
                  )
                )}
              </ul>
            </motion.nav>
          )}
        </motion.header>

        {/* Enhanced Hero Section */}
        <motion.section
          className="bg-gradient-to-r from-indigo-500 to-pink-500 dark:from-indigo-700 dark:to-pink-700 text-white pt-32 pb-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="container mx-auto text-center px-4">
            <motion.h2
              className="text-4xl md:text-6xl font-bold mb-6"
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-pink-300">
                Quản lý thời gian hiệu quả, học tập thành công
              </span>
            </motion.h2>
            <motion.p
              className="text-xl md:text-2xl mb-10 max-w-2xl mx-auto"
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-pink-300">
                QTimeTable
              </span>{" "}
              là ứng dụng mã nguồn mở miễn phí giúp bạn tối ưu hóa lịch học,
              quản lý điểm số, lưu trữ ghi chú và nâng cao hiệu suất học tập tại
              ICTU. Với giao diện thân thiện và dễ sử dụng,{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-pink-300">
                QTimeTable
              </span>{" "}
              giúp bạn dễ dàng theo dõi thời khóa biểu, lịch thi và điểm số của
              mình. Hãy trải nghiệm ngay để thấy sự khác biệt!
            </motion.p>
            <motion.div
              className="flex flex-wrap justify-center gap-4 sm:flex-col sm:gap-6"
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              <motion.a
                href="#tải-xuống"
                className="bg-yellow-400 text-indigo-900 px-8 py-4 rounded-full font-bold text-lg hover:bg-yellow-300 transition duration-300 shadow-lg inline-flex items-center justify-center mr-4"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <FaDownload className="mr-2" />
                Tải ứng dụng miễn phí
              </motion.a>
              <motion.a
                href="https://github.com/HDQuanDev/qtimetable-ictu"
                className="bg-white text-indigo-900 px-8 py-4 rounded-full font-bold text-lg hover:bg-gray-100 transition duration-300 shadow-lg inline-flex items-center justify-center"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <FaGithub className="mr-2" />
                Xem mã nguồn trên GitHub
              </motion.a>
            </motion.div>
          </div>
        </motion.section>

        {/* Enhanced Features Section */}
        <section id="tính-năng" className="py-20 bg-white dark:bg-gray-800">
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-4xl font-bold text-center mb-16 text-indigo-600 dark:text-pink-400"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Tính năng nổi bật
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  icon: Calendar,
                  title: "Lịch học thông minh",
                  description:
                    "Xem và quản lý thời khóa biểu như lịch học, lịch thi một cách trực quan, dễ dàng!",
                },
                {
                  icon: GraduationCap,
                  title: "Theo dõi điểm số",
                  description:
                    "Tra cứu điểm số, kết quả học tập của bạn một cách nhanh - gọn - lẹ - đơn giản nhất!",
                },
                {
                  icon: Clock,
                  title: "Nhắc nhở tự động",
                  description:
                    "Tự động thông báo các môn học, môn thi trước 1 ngày, 1 tiếng, 30 phút, 15 phút và khi bắt đầu vào!",
                },
                {
                  icon: SquarePlus,
                  title: "Nhiều hơn thế nữa",
                  description:
                    "Nhiều tính năng hấp dẫn khác như xem thời khóa biểu theo tuần, ghi chú, xem lịch thi theo ngày, xem điểm theo học kỳ, môn học...",
                },
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  className="bg-gradient-to-br from-indigo-100 to-pink-100 dark:from-gray-700 dark:to-purple-900 p-6 rounded-xl shadow-lg"
                  variants={fadeInUp}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: index * 0.2 }}
                  whileHover={{ scale: 1.05 }}
                >
                  <feature.icon className="w-16 h-16 mx-auto text-indigo-500 dark:text-pink-400 mb-4" />
                  <h3 className="text-xl font-semibold mb-3 text-indigo-700 dark:text-pink-300 text-center">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    {feature.description}
                  </p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Enhanced Screenshots Section */}
        <section
          id="ảnh-demo"
          className="py-20 bg-gradient-to-b from-indigo-100 to-pink-100 dark:from-gray-900 dark:to-purple-900"
        >
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-3xl md:text-4xl font-bold text-center mb-16 text-indigo-600 dark:text-pink-400"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Khám phá giao diện{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-pink-300">
                QTimeTable
              </span>
            </motion.h2>
            <div className="flex flex-col lg:flex-row items-center justify-center gap-12">
              <motion.div
                className="w-full max-w-sm"
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                <div className="relative phone-mockup">
                  <div className="absolute inset-0 bg-indigo-900 rounded-[2.5rem] shadow-2xl"></div>
                  <div className="relative border-[14px] border-indigo-900 rounded-[2.5rem] overflow-hidden">
                    <Carousel
                      showArrows={true}
                      showStatus={false}
                      showThumbs={false}
                      infiniteLoop={true}
                      autoPlay={true}
                      interval={5000}
                      onChange={(index) =>
                        setCurrentDescription(screenshots[index].description)
                      }
                      renderArrowPrev={(onClickHandler, hasPrev) =>
                        hasPrev && (
                          <button
                            onClick={onClickHandler}
                            className="absolute left-4 top-1/2 z-10 -translate-y-1/2 bg-white/80 rounded-full p-2 hover:bg-white transition-colors"
                          >
                            <ChevronLeft className="w-6 h-6 text-indigo-600" />
                          </button>
                        )
                      }
                      renderArrowNext={(onClickHandler, hasNext) =>
                        hasNext && (
                          <button
                            onClick={onClickHandler}
                            className="absolute right-4 top-1/2 z-10 -translate-y-1/2 bg-white/80 rounded-full p-2 hover:bg-white transition-colors"
                          >
                            <ChevronRight className="w-6 h-6 text-indigo-600" />
                          </button>
                        )
                      }
                    >
                      {screenshots.map((screenshot, index) => (
                        <div key={index} className="aspect-[9/16]">
                          <img
                            src={screenshot.src}
                            alt={screenshot.alt}
                            className="w-full h-full object-cover"
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </div>
              </motion.div>
              <motion.div
                className="w-full max-w-md"
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.4 }}
              >
                <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6">
                  <h3 className="text-2xl font-semibold mb-4 text-indigo-600 dark:text-pink-400">
                    Chi tiết tính năng
                  </h3>
                  <p className="text-gray-700 dark:text-gray-300 text-lg">
                    {currentDescription}
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Enhanced About Section */}
        <section
          id="giới-thiệu"
          className="py-20 bg-gradient-to-r from-indigo-500 to-pink-500 dark:from-indigo-700 dark:to-pink-700 text-white"
        >
          <div className="container mx-auto px-4">
            <motion.h2
              className="text-4xl font-bold text-center mb-8"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Về{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-pink-300">
                QTimeTable
              </span>
            </motion.h2>
            <motion.div
              className="text-center max-w-3xl mx-auto"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <p className="text-lg mb-6">
                <b>QTimeTable</b> là dự án mã nguồn mở được phát triển bởi sinh
                viên ICTU, với mục tiêu hỗ trợ cộng đồng sinh viên quản lý thời
                gian học tập hiệu quả khi mà nhà{" "}
                <i>trường chưa có ứng dụng chính thức cho việc này</i>.
              </p>
              <p className="text-lg mb-6">
                Tôi cảm thấy khó khăn khi phải theo dõi thời khóa biểu, lịch
                thi, điểm số trên trang web của trường, và đó là lý do tôi quyết
                định phát triển ứng dụng này. Tôi hy vọng rằng <b>QTimeTable</b>{" "}
                sẽ giúp ích cho bạn trong việc quản lý thời gian học tập của
                mình và giúp cho các bạn sinh viên ICTU có thể dễ dàng hơn trong
                việc theo dõi thời khóa biểu, lịch thi và điểm số của mình.
              </p>
              <p className="text-lg mb-6">
                <b>QTimeTable</b> được phát triển hoàn toàn miễn phí và mã nguồn
                mở trên GitHub. Bạn có thể xem và đóng góp vào dự án tại{" "}
                <a
                  href="https://github.com/HDQuanDev/qtimetable-ictu"
                  className="hover:underline text-yellow-300 transition duration-300"
                >
                  GitHub
                </a>
                .
              </p>
              <p className="font-semibold text-xl">
                Dự án được phát triển bởi{" "}
                <a
                  href="https://quanhd.net"
                  className="hover:underline text-yellow-300 transition duration-300"
                >
                  QuanHD
                </a>
              </p>
            </motion.div>
          </div>
        </section>

        {/* Enhanced Download Section */}
        <section id="tải-xuống" className="py-20 bg-white dark:bg-gray-800">
          <div className="container mx-auto text-center px-4">
            <motion.h2
              className="text-4xl font-bold mb-8 text-indigo-600 dark:text-pink-400"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Tải{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-pink-300">
                QTimeTable
              </span>{" "}
              ngay hôm nay
            </motion.h2>
            <motion.p
              className="mb-8 text-gray-700 dark:text-gray-300 text-lg max-w-2xl mx-auto"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              Trải nghiệm sức mạnh của <b>QTimeTable</b> trên thiết bị Android
              của bạn. Tải xuống miễn phí và bắt đầu tối ưu hóa việc học tập của
              bạn ngay hôm nay!
            </motion.p>
            {loading ? (
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
              </div>
            ) : error ? (
              <motion.p
                className="text-red-500 text-lg"
                variants={fadeInUp}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.6 }}
              >
                {error}
              </motion.p>
            ) : appInfo ? (
              <motion.div
                variants={fadeInUp}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.6, delay: 0.4 }}
                className="space-y-6"
              >
                <p className="text-gray-600 dark:text-gray-400 text-lg">
                  Phiên bản mới nhất:{" "}
                  <span className="font-semibold text-indigo-600 dark:text-pink-400">
                    {appInfo.app_version}
                  </span>
                </p>
                <motion.a
                  href={appInfo.download_url}
                  className="bg-gradient-to-r from-indigo-500 to-pink-500 text-white px-8 py-4 rounded-lg inline-flex items-center text-lg font-bold hover:from-indigo-600 hover:to-pink-600 transition duration-300 shadow-lg"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <svg
                    className="w-6 h-6 mr-2"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-1-4l-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5z" />
                  </svg>
                  Tải APK cho Android
                </motion.a>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-4">
                  *Lưu ý: Hãy cho phép cài đặt từ nguồn không xác định trong cài
                  đặt Android của bạn để cài đặt ứng dung.
                </p>

                <motion.div
                  className="bg-indigo-100 dark:bg-indigo-900 rounded-lg p-6 mb-8 mx-auto max-w-2xl text-gray-800 dark:text-gray-200 shadow-md"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <div className="flex items-center justify-center mb-4">
                    <Info className="w-6 h-6 mr-2 text-indigo-600 dark:text-indigo-400" />
                    <h4 className="text-xl font-semibold text-indigo-700 dark:text-indigo-300">
                      Thông tin quan trọng
                    </h4>
                  </div>
                  <ul className="text-sm space-y-3 text-left list-disc list-inside">
                    <li className="text-gray-700 dark:text-gray-300">
                      QTimeTable là một dự án cá nhân được phát triển bởi Hứa
                      Đức Quân.
                    </li>
                    <li className="text-gray-700 dark:text-gray-300">
                      Ứng dụng này không có liên kết chính thức với bất kỳ tổ
                      chức giáo dục nào.
                    </li>
                    <li className="text-gray-700 dark:text-gray-300">
                      Mọi dữ liệu người dùng đều được lưu trữ cục bộ trên thiết
                      bị của bạn, đảm bảo quyền riêng tư tối đa.
                    </li>
                    <li className="text-gray-700 dark:text-gray-300">
                      Ngoại trừ Dữ Liệu Ghi Chú được mã hoá bằng mã P2P của bạn
                      và lưu trữ tại máy chủ FireStore của tôi kèm theo thông
                      tin mã Token Push Notification và tên thiết bị của bạn.
                    </li>
                  </ul>
                </motion.div>
              </motion.div>
            ) : (
              <motion.p
                className="text-gray-600 dark:text-gray-400 text-lg"
                variants={fadeInUp}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.6 }}
              >
                Thông tin tải xuống hiện không khả dụng. Vui lòng thử lại sau.
              </motion.p>
            )}
          </div>
        </section>

        {/* Enhanced Footer */}
        <footer className="bg-gradient-to-r from-indigo-800 to-pink-800 text-white py-12">
          <div className="container mx-auto text-center px-4">
            <motion.div
              className="mb-8 space-y-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              <h3 className="text-2xl font-bold">QTimeTable</h3>
              <p className="text-lg">
                Ứng dụng mã nguồn mở Đa Năng dành cho các bạn sinh viên ICTU
              </p>
            </motion.div>
            <motion.div
              className="flex justify-center space-x-6 mb-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <a
                href="https://www.facebook.com/quancp72h"
                className="hover:text-yellow-300 transition duration-300"
              >
                <span className="sr-only">Facebook</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a
                href="https://instagram.com/huaducquan.sp"
                className="hover:text-yellow-300 transition duration-300"
              >
                <span className="sr-only">Instagram</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <motion.a
                href="https://github.com/HDQuanDev/qtimetable-ictu"
                target="_blank"
                className="hover:text-yellow-300 transition duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <span className="sr-only">GitHub</span>
                <Github className="w-6 h-6" />
              </motion.a>
            </motion.div>

            <motion.p
              className="text-sm"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              © {new Date().getFullYear()} QTimeTable. Bản quyền thuộc về{" "}
              <a
                href="https://quanhd.net"
                className="hover:underline text-yellow-300 transition duration-300"
              >
                QuanHD
              </a>
            </motion.p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default App;
